import React, { useEffect } from 'react'

const RedirectPage = () => {
  useEffect(() => {
    document.location.href = `/news`
  })

  return <div></div>
}

export default RedirectPage
